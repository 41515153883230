import { UserService } from './../../services/user/user.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ToastManager } from '@porsche-design-system/components-angular';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private user: UserService,
    private toastManager: ToastManager
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /********** Add Authroziation token in Request header if available **********/
    const token = this.user.getIdToken();

    if (token) {
      let headers = new HttpHeaders();

      console.log(request.url.indexOf("files"));
      if (request.url.indexOf("files") != -1) {

        let header = {
          headers: new HttpHeaders()

            .set('accept', 'application/json')
            .set('Authorization', token)
        }

        const cloned = request.clone(header);

        return next.handle(cloned).pipe(catchError((err: any) => {
          console.log(err);
          if (err.status === 401 || err.error === 'invalid_token') {
            this.toastManager.addMessage({ text: 'Your session has expired. Please login again!', state: 'neutral' });
            this.user.userSignOut();
          }
          return throwError(err);
        }));

      } else {


        let header = {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('accept', 'application/json')
            .set('Authorization', token)
        }
        const cloned = request.clone(header);

        return next.handle(cloned).pipe(catchError((err: any) => {
          console.log(err);
          if (err.status === 401 || err.error === 'invalid_token') {
            this.toastManager.addMessage({ text: 'Your session has expired. Please login again!', state: 'neutral' });
            this.user.userSignOut();
          }

          return throwError(err);
        }));
      }



    }
    else {
      const headers = new HttpHeaders()
        .set('accept', 'application/json')
        .set('Content-Type', 'application/json');
      const cloned = request.clone({
        headers: headers
      });
      return next.handle(cloned).pipe(catchError((err: any) => {
        console.log(err);
        if (err.status === 401 || err.error === 'invalid_token') {
          this.toastManager.addMessage({ text: 'Your session has expired. Please login again!', state: 'neutral' });
          this.user.userSignOut();
        }

        return throwError(err);
      }));
    }
  }
}
