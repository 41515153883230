import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ApiUrl } from 'src/app/core/apiUrl';
import { HttpService } from '../http/http.service';
import { ToastManager } from '@porsche-design-system/components-angular';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUserSubject = new BehaviorSubject(null);
  currentUser = this.currentUserSubject.asObservable();
  requestData: any;

  constructor(
    private router: Router,
    private http: HttpService,
    private toastManager: ToastManager
  ) {

    /*** Check currentUser from local storage ***/
    let userData: any = null;
    try {
      userData = localStorage.getItem('userData') ? localStorage.getItem('userData') : null;
      userData = userData ? JSON.parse(userData) : null;
      this.currentUserSubject = new BehaviorSubject<any>(userData.data.authentication);

    } catch (error) {
      if (error instanceof SyntaxError) { this.removeUser(); }
    }
  }

  getApiToken() {
    let userData: any = null;
    if (localStorage.getItem('userData')) {
      userData = localStorage.getItem('userData') ? localStorage.getItem('userData') : null;
      userData = userData ? JSON.parse(userData) : null;
      return userData.data.authentication.accessToken;
    } else {
      return "";
    }
  }

  getuserData() {
    let userData: any = null;
    if (localStorage.getItem('userData')) {
      userData = localStorage.getItem('userData') ? localStorage.getItem('userData') : null;
      userData = userData ? JSON.parse(userData) : null;
      return userData.data.authentication.user;
    } else {
      return "";
    }
  }
  getIdToken() {
    let userData: any = null;
    if (localStorage.getItem('userData')) {
      userData = localStorage.getItem('userData') ? localStorage.getItem('userData') : null;
      userData = userData ? JSON.parse(userData) : null;
      return userData.data.authentication?.idToken;
    } else {
      return "";
    }
  }

  getRoleGroup() {
    let userData: any = localStorage.getItem('userData') ? localStorage.getItem('userData') : null;
    userData = userData ? JSON.parse(userData) : null;
    return userData?.data?.roleInfo;
  }
  isAuthenticated() {
    return localStorage.getItem('userData') ? true : false;
  }
  setUserLocalData(data: any): void {
    const userData = {
      data: data,
      timestamp: new Date().getTime() + 60 * 30 * 1000
    };
    localStorage.setItem('userData', JSON.stringify(userData));
    this.currentUserSubject.next(userData.data);
  }
  checkSession() {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const currentTime = new Date().getTime();
      if (currentTime <= parsedData.timestamp) {
        console.log(parsedData.data);
      } else {
        this.userSignOut();
        this.router.navigate(['/login']); // For local
        //    this.getUserData(); // For prod
      }
    } else {
      this.router.navigate(['/login']); // For local
      //  this.router.navigate(['/pcl-auth']); // For prod
    }
  }

  /*** Remove user from local storage ***/
  removeUser(): void {
    localStorage.removeItem('userData');
    if (this.currentUserSubject) {
      this.currentUserSubject.next(null);
    }
  }

  /*** User Sign-Out ***/
  userSignOut(): void {
    this.router.navigate(['/login']);

    setTimeout(() => {
      this.removeUser();
    },1000)
  }
}
