import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoaderService } from './services/loader/loader.service';
import { UserService } from './services/user/user.service';
import { Location } from '@angular/common';
import { ToastManager } from '@porsche-design-system/components-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  show = false;
  loaderSubscription!: Subscription;
  currentLang = '';
  timer: any;
  constructor(
    private loaderService: LoaderService,
    private ref: ChangeDetectorRef,
    private toastManager: ToastManager
  ) {
    setInterval(() => {
      let ele: any = document.querySelectorAll(".mdc-button__label")[0];
      if (ele) {
        ele.querySelector("span").innerHTML = document.querySelectorAll(".mdc-button__label")[0].querySelector("span")?.textContent?.split("T")[0].split("-")[0];
      }
    }, 10)
  }



  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event: any) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      console.log(window.outerWidth, window.outerHeight)
      if (window.outerWidth < window.outerHeight) {
        this.toastManager.addMessage({ text: 'For the best viewing experience, please rotate your Device to a landscape view', state: 'neutral' });
      }
    }, 1000);
  }


  ngOnInit(): void {
    console.log(window.outerWidth, window.outerHeight)
    if (window.outerWidth < window.outerHeight) {
      this.toastManager.addMessage({ text: 'For the best viewing experience, please rotate your Device to a landscape view', state: 'neutral' });
    }

    this.loaderSubscription = this.loaderService.loaderState
      .subscribe((state) => {
        this.show = state.show;
        this.ref.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.show = false;
    this.loaderSubscription.unsubscribe();
  }

}
